<template>
  <form action="" @submit.prevent="submitForm()">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Sprachdatei {{ !!audio ? 'bearbeiten' : 'hinzufügen' }}</p>
                        <button
                            type="button"
                            class="delete"
                            @click="$emit('close')"/>
                    </header>
                    <section class="modal-card-body">
                        <b-field label="Text der Sprachdatei">
                            <b-input
                                type="textarea"
                                placeholder="Text eingeben..."
                                v-model="text"
                                required>
                            </b-input>
                        </b-field>

                        <b-field>
                            <b-radio-button v-model="selector"
                                native-value="url"
                                type="is-primary is-light is-outlined">
                                <span>URL</span>
                            </b-radio-button>

                            <b-radio-button v-model="selector"
                                native-value="file"
                                type="is-primary is-light is-outlined">
                                <span>Datei</span>
                            </b-radio-button>
                        </b-field>  
                        
                        <b-field label="URL" v-if="selector === 'url'">
                            <b-input
                                type="text"
                                placeholder="https://..."
                                v-model="url"
                                required>
                            </b-input>
                        </b-field>

                         <b-field class="file is-primary" :class="{'has-name': !!file}" v-else>
                              <b-upload v-model="file" class="file-label" accept=".mp3,.wav">
                                  <span class="file-cta">
                                      <b-icon class="file-icon" icon="upload"></b-icon>
                                      <span class="file-label">Klicken zum Hochladen</span>
                                  </span>
                                  <span class="file-name" v-if="file">
                                      {{ file.name }}
                                  </span>
                              </b-upload>
                          </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Schließen"
                            @click="$emit('close')" />
                        <b-button
                            label="Speichern"
                            native-type="submit"
                            type="is-primary" />
                    </footer>
                </div>
            </form>
</template>

<script>
import Api from '../apis/Api.js'

export default {
  name: 'AudioFileModal',
  props: {
    audio: Object,
    scenario: Object
  },
  data() {
    return {
      text: '',
      url: '',
      file: null,
      selector: 'url'
    }
  },
    mounted() {
    if(this.audio != null) {
        this.text = this.audio.text;
        this.url = this.audio.url;
    }
  },
    methods: {
      submitForm() {
          if(this.selector === 'url') {
              this.file = null;
          } else {
              this.url = '';
          }

            const formData = new FormData();
            formData.append('meta', JSON.stringify({
                text: this.text,
                url: this.url
            }));

            if(this.file !== null) {
                formData.append('audio', this.file);
            }

          if(this.audio == null) {
            Api.post("/scenarios/" + this.scenario.id + "/audios", formData)
            .then((response) => {
                                this.scenarios = response.data;
                                this.$emit('reload');
                                this.$emit('close');
                            });
          } else {
              formData.append('_method', 'put');

            Api.post("/audios/" + this.audio.id, formData)
            .then((response) => {
                                this.scenarios = response.data;
                                this.$emit('reload');
                                this.$emit('close');
                            });
          }
      }
  }
}
</script>