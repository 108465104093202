<template>
  <div id="app">
        <b-navbar>
        <template #brand>
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <b-icon
                icon="phone-flip"
                type="is-success"
                class="mr-3" /><b>Prialer</b>
            </b-navbar-item>
        </template>
        <template #start>
            <router-link tag="b-navbar-item" to="/scenarios" v-if="isLoggedIn()">
                Szenarien
            </router-link>
            <!-- <router-link tag="b-navbar-item" to="/recordings">
                Aufzeichnungen
            </router-link> -->
        </template>

        <template #end v-if="isLoggedIn()">
            <b-navbar-item tag="div">
                <div class="buttons">
                    <b-button type="is-light" @click="doLogout()" :loading="logoutLoading" :disabled="logoutLoading">
                        Abmelden
                    </b-button>
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>
    <router-view></router-view>
  </div>
</template>

<script>
import User from "./apis/User";

export default {
  name: 'App',
  data() {
    return {
      logoutLoading: false
    };
  },
  methods: {
      isLoggedIn() {
        return localStorage.getItem("auth");
      },
      doLogout() {
        this.logoutLoading = true;

        User.logout()
            .then(() => {
                localStorage.removeItem("auth");
                this.$router.replace({ name: "Login" });
            })
            .catch(error => {
                alert('Ein Fehler ist aufgetreten (Abmeldevorgang)');
            })
            .finally(_ => {
                this.logoutLoading = false;
            });
      }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
