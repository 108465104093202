<template>
    <div class="container">
        <div class="columns">
            <div class="column">
                <div class="card">
                    <div class="card-content">
                        <h1 class="title is-1 mb-0">Live-Gespräch</h1>
                        <p class="mb-5 mt-3">Sie wurden zu einer Echtzeit-Gesprächsübertragung eingeladen.<br>
                            <b>Hinweis:</b> Klicken Sie nach dem Beitreten auf den Mikrofon-Button, wenn Sie sprechen möchten.</p>
                        <b-button type="is-success" :loading="loading" :disabled="loading" v-if="!shown" @click="join()">Beitreten</b-button>
                        <b-button type="is-danger" :loading="loading" :disabled="loading" v-if="shown" @click="hangup()">Verlassen</b-button>

                        <p style="font-weight: bold; color: red" class="mt-5" v-if="error != null">{{ error }}</p>
                        <div id="callFrame" class="mt-2" style="display: none"></div>
                    </div>
                </div>
            </div>
        </div>
        <script src="https://talk.encrypted.at/external_api.js" type="application/javascript"></script>
    </div>
</template>

<script>
export default {
  name: 'Listen',
  data() {
    return {
        room: this.$route.params.room,
        shown: false,
        api: null,
        loading: false,
        shown: false,
        error: null
    }
  },
  methods: {
      join() {
        this.loading = true;
        this.error = null;

        const domain = 'talk.encrypted.at';
        const options = {
            roomName: "prialer-" + this.room,
            width: 500,
            height: 500,
            parentNode: document.querySelector('#callFrame'),
            lang: 'de',
            configOverwrite: {
                startWithAudioMuted: true,
                startWithVideoMuted: true,
                prejoinConfig: { enabled: false },
                disableInitialGUM: true,
                toolbarButtons: ['microphone'],
                disabledSounds: ['PARTICIPANT_JOINED_SOUND','PARTICIPANT_LEFT_SOUND','TALK_WHILE_MUTED_SOUND','OUTGOING_CALL_START_SOUND'],
                disableSelfView: true,
                disableSelfViewSettings: true,
                hideConferenceSubject: true,
                hideConferenceTimer: true,
                hideParticipantsStats: true,
                disableDeepLinking: true,
                doNotStoreRoom: true,
                disablePolls: true,
                apiLogLevels: ['warn']
            },
            interfaceConfigOverwrite: {
                CONNECTION_INDICATOR_DISABLED: true,
                DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                DISABLE_RINGING: true,
                VIDEO_QUALITY_LABEL_DISABLED: true
            }
        };

        this.api = new JitsiMeetExternalAPI(domain, options);

        this.api.addListener("errorOccurred", (err) => {
            if(err != null && err.error.name != undefined) {
                if(err.error.name === "conference.authenticationRequired") {
                    this.setError("Es existiert (noch) kein Sprachkanal für den verwendeten Link.");
                }
            }
        });

        this.api.addListener("readyToClose", () => {
            this.shown = false;
            this.loading = false;
            document.querySelector('#callFrame').innerHTML = "";
            document.querySelector('#callFrame').style.display = "none";
        });

        this.api.addListener("participantJoined", () => {
            this.shown = true;
            this.loading = false;
            document.querySelector('#callFrame').style.display = "block";
        });
      },
      setError(message) {
          this.error = message;
          this.hangup();
      },
      hangup() {
          this.loading = true;
          this.api.executeCommand('hangup');
      }
  }
}
</script>