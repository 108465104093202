<template>
<div class="container">
    <div class="columns">
        <div class="column">
            <div class="card">
                <div class="card-content">
                    <h1 class="title is-1">Aufzeichnungen</h1>
                    <p>Derzeit sind keine Aufzeichnungen verfügbar.</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'Recordings'
}
</script>