<template>
    <div class="container">
        <div class="columns">
            <div class="column">
                <div class="card">
                    <div class="card-content">
                        <h1 class="title is-1 mb-0">Anmelden</h1>
                        <p class="mb-5">Melden Sie sich an, um auf die Anwendung zuzugreifen.</p>

                        <form @submit.prevent="login">
                            <div class="columns">
                                <div class="column is-one-third">
                                    <b-field label="E-Mail">
                                        <b-input
                                            type="email"
                                            placeholder="E-Mail"
                                            v-model="form.email"
                                            required>
                                        </b-input>
                                    </b-field>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third">                   
                                    <b-field label="Passwort">
                                        <b-input
                                            type="password"
                                            placeholder="Passwort"
                                            v-model="form.password"
                                            required>
                                        </b-input>
                                    </b-field>
                                </div>
                            </div>

                            <p style="font-weight: bold; color: red;" class="mb-4" v-if="error != null">{{ error }}</p>
                            <b-button type="is-success" native-type="submit" :loading="loading" :disabled="loading">Anmelden</b-button>
                            <router-link tag="b-button" class="is-text ml-2" to="/register" v-if="registerAllowed">Stattdessen registrieren</router-link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import User from "../apis/User"
import Api from '../apis/Api.js'

export default {
  name: 'Login',
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      registerAllowed: false,
      error: null,
      loading: false
    };
  },
  created() {
    Api.get("/auth/allowed").then((response) => {
        this.registerAllowed = response.data.registration;
    });
  },
    methods: {
    login() {
        if(this.loading) return;

        this.error = null;
        this.loading = true;

        User.login(this.form)
        .then(() => {
          this.$root.$emit("login", true);
          localStorage.setItem("auth", "true");
          this.$router.replace({ name: "Scenarios" });
        })
        .catch(error => {
          if (error.response.status === 401) {
              this.error = "Bitte überprüfen Sie die eingegebenen Daten.";
          } else  {
              this.error = "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.";
          }
        })
        .finally(_ => {
          this.loading = false;
        });
    }
  }
}
</script>