<template>
<div class="container">
    <div class="columns mt-3">
        <div class="column">
            <h1 class="title is-1">Prialer</h1>
            
            <p class="title is-3">Online-Anrufe mit vorgefertigten Sprachaufnahmen</p>
            <p>Einfacher geht's nicht: Szenario online auswählen, vorher ausprobieren und danach<br>einfach eine Telefonnummer eingeben und lostelefonieren.</p>
            <router-link tag="b-button" to="/scenarios" type="is-success" class="mt-4">Zur Szenarioauswahl</router-link>  
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Scenarios'
}
</script>