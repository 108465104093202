<template>
  <div class="container">
    <div class="columns mt-3">
        <div class="column">
            <h1 class="title is-1">404</h1>
            
            <p class="title is-3">Seite nicht gefunden</p>
            <p>Die angeforderte Seite konnte leider nicht gefunden werden.<br>
              Eventuell wurde ein veralteter oder verschobener Link verwendet.</p>
            <router-link tag="b-button" to="/scenarios" type="is-success" class="mt-4">Zur Szenarioauswahl</router-link>  
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>