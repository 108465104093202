<template>
<div class="container" v-if="scenario != null">
    <div class="columns">
        <div class="column">
            <div class="card">
                <div class="card-content">
                    <h1 class="title is-1">{{ scenario.title }}</h1>
                    <p>{{ scenario.description }}</p>
                    <script src="https://talk.encrypted.at/external_api.js" type="application/javascript"></script>
                </div>
            </div>
        </div>
    </div>

    <div class="columns">
        <div class="column">
            <div class="card">
                <div class="card-content">
                    <h2 class="title is-3">Telefonnummer eingeben</h2>
                    <b-field class="m-0" label="Format: 066412345678 (Österreich) bzw. mit Landesvorwahl 00xx (International)">
                        <b-input placeholder="Nummer eingeben..." type="text" v-model="phoneNo" v-on:keyup.native.enter="initiateCall()"
                        :disabled="jitsiDetails != null || callState != null"></b-input>
                        <p class="control"><b-button class="button is-success" :disabled="jitsiDetails != null || callState != null"
                            @click="initiateCall()">Anrufen</b-button>
                            </p>
                            <b-button class="ml-3 button is-danger" v-if="jitsiDetails != null" @click="hangupCall()">Auflegen</b-button>

                            <b-button class="ml-3 button is-info" v-if="jitsiDetails != null" icon-right="clipboard"
                                @click="copyToClipboard(jitsiDetails.room)"></b-button>

                                <b-button class="ml-3 button is-info" v-if="jitsiDetails != null"
                                    :icon-left="micEnabled ? 'microphone' : 'microphone-slash'" @click="toggleMic()"></b-button>

                                <b-button class="button is-text ml-3" disabled style="cursor: default; text-decoration: none" v-if="callState != null">
                                    <span style="color: grey">
                                    {{ callState }}
                                    </span></b-button>
                    </b-field>
                    <div id="callFrame" style="width: 0; height: 0"></div>
                </div>
            </div>
        </div>
    </div>

    <!-- Audio files -->
    <div class="columns">
        <div class="column">
            <div class="card">
                <div class="card-content">
                    <div class="columns">
                                <div class="column">
                                                        <h2 class="title is-3">Sprachdateien</h2>
                                </div>
                                <div class="column is-1 has-text-right">
                                    <b-button type="is-success" size="is-small" @click="currentAudio = null; isEditModalActive = true" icon-right="plus" />
                                </div>
                    </div>
                    <p class="mb-4">Anklicken zum Abspielen - auch vor dem Anruf, zum Beispiel als Vorschau</p>

                    <!-- Audio file list -->
                    <div class="card" v-for="(audio, index) in scenario.audio_files" :key="audio.id">
                        <div class="card-content p-3 mt-3 audio-card">
                            <div class="columns">
                                <div class="column" @click="playSound(audio)">
                                    <b>{{ index + 1 }}.</b> {{ audio.text }}
                                </div>
                                <div class="column is-1 has-text-right">
                                    <b-button type="is-info" size="is-small" @click="editAudio(audio)"
                                        class="mr-1" icon-right="pencil" />
                                    <b-button type="is-danger" size="is-small" @click="deleteAudio(audio)" 
                                        icon-right="trash" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Edit/Add modal -->
    <b-modal
            v-model="isEditModalActive"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-label="Sprachdatei hinzufügen/bearbeiten"
            close-button-aria-label="Schließen"
            aria-modal>
            <template #default="props">
                <audio-file-modal :audio="currentAudio" :scenario="scenario" @close="props.close" @reload="fetchData()" :key="!!currentAudio ? currentAudio.id : -1"></audio-file-modal>
            </template>
        </b-modal>

    <!-- Delete modal -->
    <b-modal
            v-model="isDeleteModalActive"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-label="Sprachdatei löschen"
            close-button-aria-label="Schließen"
            aria-modal>
            <template #default="props">
                <delete-audio-modal :audio="currentAudio" @close="props.close" @reload="fetchData()" :key="!!currentAudio ? currentAudio.id : -1"></delete-audio-modal>
            </template>
        </b-modal>
  </div>
</template>

<script>
import AudioFileModal from '../modals/AudioFileModal.vue'
import DeleteAudioModal from '../modals/DeleteAudioModal.vue'
import Api from '../apis/Api.js'

export default {
  name: 'ScenarioDetails',
  components: {
            AudioFileModal,
            DeleteAudioModal
        },
        data() {
            return {
                isEditModalActive: false,
                isDeleteModalActive: false,
                currentAudio: null,
                scenario: null,
                phoneNo: '',
                jitsiDetails: null,
                callState: null,
                cid: null,
                api: null,
                micEnabled: false
            }
        },
        mounted() {
            this.fetchData();
        },
  methods: {
      fetchData() {
          this.currentAudio = null;
          Api.get("/scenarios/" + this.$route.params.id).then((response) => {
                this.scenario = response.data;
            });
      },
        playSound(audioFile) {
            // let audio = new Audio(audioFile.url);
            // audio.play();
            console.log("Jup");
            Api.post("/voip/play", {
                fileUrl: audioFile.url
            }).then((response) => {
                console.log(response.data);
            });
        },
        hangupCall() {
            this.callState = "Verbindung trennen..."
            Api.delete("/voip/call")
            .then((response) => {
                console.log("Hung up call!");
                this.jitsiDetails = null;
                this.callState = null;
                this.api.executeCommand('hangup');
            });
        },
        initiateCall() {
            if(this.phoneNo.length <= 4) return;

            this.callState = "Kommunikationsaufbau..."
                  Api.post("/voip/call", {
                      phoneNo: this.phoneNo
                  }).then((response) => {
                this.jitsiDetails = response.data.jitsi;
                console.log(this.jitsiDetails);

                const domain = 'talk.encrypted.at';
                const options = {
                    roomName: this.jitsiDetails.room,
                    width: 0,
                    height: 0,
                    parentNode: document.querySelector('#callFrame'),
                    lang: 'de',
                    jwt: this.jitsiDetails.token,
                    configOverwrite: {
                        startWithAudioMuted: true,
                        startWithVideoMuted: true,
                        prejoinConfig: { enabled: false },
                        disableInitialGUM: true,
                        toolbarButtons: [],
                        disabledSounds: ['PARTICIPANT_JOINED_SOUND','PARTICIPANT_LEFT_SOUND','TALK_WHILE_MUTED_SOUND','OUTGOING_CALL_START_SOUND'],
                        disableSelfView: true,
                        disableSelfViewSettings: true,
                        hideConferenceSubject: true,
                        hideConferenceTimer: true,
                        hideParticipantsStats: true,
                        disableDeepLinking: true,
                        doNotStoreRoom: true,
                        disablePolls: true
                    },
                    interfaceConfigOverwrite: {
                        CONNECTION_INDICATOR_DISABLED: true,
                        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                        DISABLE_RINGING: true,
                        VIDEO_QUALITY_LABEL_DISABLED: true
                    }
                };

                this.api = new JitsiMeetExternalAPI(domain, options);
                this.callState = "Warte auf Telefoniedienst..."
                
                this.api.addListener("participantJoined", (caller) => {
                    if(caller.displayName === "412") {
                        // Caller joined
                        this.callState = "Telefonleitung verbunden"
                        this.cid = caller.id;
                    }
                });

                this.api.addListener("participantLeft", (caller) => {
                    if(caller.id === this.cid) {
                        // Caller left
                        this.jitsiDetails = null;
                        this.callState = null;
                        this.cid = null;
                    }
                });

                this.api.addListener("audioMuteStatusChanged", (status) => {
                    this.micEnabled = !status.muted;
                });

                this.api.addListener("readyToClose", () => {
                    document.querySelector('#callFrame').innerHTML = '';
                })
            });   
    },
    copyToClipboard(room) {
        let text = "https://prialer.rofl.li/listen/" + room.substring(8);
        navigator.clipboard.writeText(text);

        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
    },
    toggleMic() {
        this.api.executeCommand('toggleAudio');
    },
    deleteAudio(audio) {
          this.currentAudio = audio;
          this.isDeleteModalActive = true;
      },
      editAudio(audio) {
          this.currentAudio = audio;
          this.isEditModalActive = true;
      }
  }
}
</script>

<style scoped>
.audio-card {
    background-color: #f2f2f2;
    cursor: pointer;
    user-select: none;
}

.audio-card:hover {
    background-color: #e6e6e6;
}
</style>