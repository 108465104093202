<template>
<div class="container">
    <div class="columns">
        <div class="column">
            <div class="card">
                <div class="card-content">
                    <div class="columns">
                                <div class="column">
                                    <h1 class="title is-1">Szenarienauswahl</h1>
                                </div>
                                <div class="column is-1 has-text-right">
                                    <b-button type="is-success" size="is-small" @click="currentScenario = null; isEditModalActive = true" icon-right="plus" />
                                </div>
                    </div>
                    <p>Treffen Sie eine Auswahl aus der Liste, um einen Anruf zu starten.</p>
                </div>
            </div>

            <div class="columns is-multiline" v-if="scenarios != null && scenarios.length > 0">
                <template v-for="scenario in scenarios">
                    <div class="column is-one-third mt-4" :key="scenario.id">
                        <div class="card">
                            <div class="card-content">
                                <h2 class="title is-3">{{ scenario.title }}</h2>
                                <p>{{ scenario.description }}</p>

                                <div class="columns is-vcentered mt-3">
                                    <div class="column">
                                        <router-link tag="b-button" :to="'/scenarios/' + scenario.id" class="is-success">Starten</router-link>
                                    </div>
                                    <div class="column is-3 has-text-right">
                                        <b-button type="is-info" size="is-small" @click="editScenario(scenario)"
                                            class="mr-1" icon-right="pencil" />
                                        <b-button type="is-danger" size="is-small" @click="deleteScenario(scenario)" 
                                            icon-right="trash" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div v-else-if="scenarios != null">
                <p class="mt-3">Erstellen Sie mit dem grünen Button ein neues Szenario, um fortzufahren.</p>
            </div>
            <div v-else>
                <p class="mt-3">Wird geladen...</p>
            </div>
        </div>
    </div>


    <!-- Edit/Add modal -->
    <b-modal
            v-model="isEditModalActive"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-label="Szenario hinzufügen/bearbeiten"
            close-button-aria-label="Schließen"
            aria-modal>
            <template #default="props">
                <scenario-modal :scenario="currentScenario" @close="props.close" @reload="fetchData()" :key="!!currentScenario ? currentScenario.id : -1"></scenario-modal>
            </template>
        </b-modal>

    <!-- Delete modal -->
    <b-modal
            v-model="isDeleteModalActive"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-label="Szenario löschen"
            close-button-aria-label="Schließen"
            aria-modal>
            <template #default="props">
                <delete-scenario-modal :scenario="currentScenario" @close="props.close" @reload="fetchData()" :key="!!currentScenario ? currentScenario.id : -1"></delete-scenario-modal>
            </template>
        </b-modal>
  </div>
</template>

<script>
import ScenarioModal from '../modals/ScenarioModal.vue'
import DeleteScenarioModal from '../modals/DeleteScenarioModal.vue'
import Api from '../apis/Api.js'

export default {
  name: 'Scenarios',
  components: {
      ScenarioModal,
      DeleteScenarioModal
  },
  data() {
    return {
        isEditModalActive: false,
        isDeleteModalActive: false,
        currentScenario: null,
        scenarios: null
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
      fetchData() {
          Api.get("/scenarios").then((response) => {
                this.scenarios = response.data;
            });
      },
      deleteScenario(scenario) {
          this.currentScenario = scenario;
          this.isDeleteModalActive = true;
      },
      editScenario(scenario) {
          this.currentScenario = scenario;
          this.isEditModalActive = true;
      }
  }
}
</script>