<template>
    <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Sprachdatei löschen</p>
                        <button
                            type="button"
                            class="delete"
                            @click="$emit('close')"/>
                    </header>
                    <section class="modal-card-body">
                        Soll die Sprachdatei (ID {{ audio.id }}) wirklich gelöscht werden?
                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Schließen"
                            @click="$emit('close')" />
                        <b-button
                            label="Löschen"
                            @click="doDelete()"
                            type="is-danger" />
                    </footer>
    </div>
</template>

<script>
import Api from '../apis/Api.js'

export default {
  name: 'DeleteAudioModal',
  props: {
    audio: Object
  },
  data() {
    return {
      
    }
  },
  methods: {
    doDelete() {
           Api.delete("/audios/" + this.audio.id)
           .then((response) => {
                    this.$emit('reload');
                    this.$emit('close');
                });
    }
  }
}
</script>