<template>
  <form action="" @submit.prevent="submitForm()">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Szenario {{ !!scenario ? 'bearbeiten' : 'hinzufügen' }}</p>
                        <button
                            type="button"
                            class="delete"
                            @click="$emit('close')"/>
                    </header>
                    <section class="modal-card-body">
                        <b-field label="Titel">
                            <b-input
                                type="text"
                                placeholder="Titel eingeben..."
                                v-model="title"
                                required>
                            </b-input>
                        </b-field>

                        <b-field label="Beschreibung">
                            <b-input
                                type="textarea"
                                placeholder="Beschreibung eingeben..."
                                v-model="description"
                                required>
                            </b-input>
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Schließen"
                            @click="$emit('close')" />
                        <b-button
                            label="Speichern"
                            native-type="submit"
                            type="is-primary" />
                    </footer>
                </div>
            </form>
</template>

<script>
import Api from '../apis/Api.js'

export default {
  name: 'ScenarioModal',
  props: {
    scenario: Object
  },
  data() {
    return {
      title: '',
      description: ''
    }
  },
  mounted() {
    if(this.scenario != null) {
        this.title = this.scenario.title;
        this.description = this.scenario.description;
    }
  },
  methods: {
      submitForm() {
          if(this.scenario == null) {
            Api.post("/scenarios", {
                            title: this.title,
                            description: this.description
                        }).then((response) => {
                                this.scenarios = response.data;
                                this.$emit('reload');
                                this.$emit('close');
                            });
          } else {
            Api.put("/scenarios/" + this.scenario.id, {
                            title: this.title,
                            description: this.description
                        }).then((response) => {
                                this.scenarios = response.data;
                                this.$emit('reload');
                                this.$emit('close');
                            });
          }
      }
  }
}
</script>