import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);
Vue.component('vue-fontawesome', FontAwesomeIcon);

import App from './App.vue'
import NotFound from './components/NotFound.vue'
import Scenarios from './components/Scenarios.vue'
import ScenarioDetails from './components/ScenarioDetails.vue'
import Recordings from './components/Recordings.vue'
import Home from './components/Home.vue'
import Login from './components/Login.vue'
import Register from './components/Register.vue'
import Listen from './components/Listen.vue'

Vue.use(VueRouter)
Vue.use(VueAxios, axios)

Vue.config.productionTip = false
axios.defaults.withCredentials = true;

Vue.use(Buefy, {
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fas",
  customIconPacks: {
    fas: {
      sizes: {
        default: "lg",
        "is-small": "1x",
        "is-medium": "2x",
        "is-large": "3x"
      },
      iconPrefix: ""
    }
  }
});

const routes = [
  { path: '/', component: Home },
  { path: '/login', component: Login, name: "Login", meta: { guest: true } },
  { path: '/register', component: Register, name: "Register", meta: { guest: true } },
  { path: '/listen/:room', component: Listen, name: "Listen", meta: { guest: true } },
  { path: '/scenarios', component: Scenarios, name: "Scenarios" },
  { path: '/scenarios/:id', component: ScenarioDetails, name: "ScenarioDetails" },
  { path: '/recordings', component: Recordings, name: "Recordings" },
  { path: '/:path(.*)*', component: NotFound, name: 'NotFound' }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

function isLoggedIn() {
  return localStorage.getItem("auth");
}

router.beforeEach((to, from, next) => {
  if (!to.matched.some(record => record.meta.guest)) {
    if (!isLoggedIn()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;

const app = new Vue({
  router,
  ...App
}).$mount('#app')