import axios from "axios";

let Api = axios.create({
  baseURL: "https://api.prialer.rofl.li"
});

Api.defaults.withCredentials = true;
Api.interceptors.response.use(
    response => {
      return response;
    },
    error => {
        if (error.response?.status === 401) {
            localStorage.removeItem("auth");
        }

        return Promise.reject(error);
    }
);

export default Api;
